

<template>
<div>
    <vs-popup fullscreen :title="showProductCartPopup.title" :active.sync="isPopupActiveLocal">

        <div class="vx-row">
            <vs-row vs-justify="center">
                <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="6" vs-xs="12">
                <!-- IMG COL -->
                <div class="vx-col w-full item-img-container bg-white flex items-center justify-center cursor-pointer">
                  <img
                    v-if="showProductCartPopup.image && showProductCartPopup.image.medium"
                    :src="showProductCartPopup.image.medium"
                    :alt="showProductCartPopup.title"
                    class="p-4"
                    style="max-width: 100%; max-height: 100%; width: auto; -webkit-transition: .35s;"
                  >
                  <img
                    v-else
                    src="@/assets/images/no-image-400x400.png"
                    class="p-4"
                    style="max-width: 100%; max-height: 100%; width: auto; -webkit-transition: .35s;"
                  >
                </div>
                </vs-col>

                <!-- ITEM NAME & DESC COL -->
                <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="6" vs-xs="12">
                  <div class="pt-6">
                      <slot name="item-meta">
                          <h6 class="mb-2 text-lg text-gray-900">{{ showProductCartPopup.title }}
                            <span class="float-right">{{ parseFloat(showProductCartPopup.total).toFixed(2) }} €</span>
                          </h6>
                                      
                          <div class="my-4" v-if="showProductCartPopup.category">
                              <p class="text-sm font-medium truncate">{{ showProductCartPopup.category }}</p>
                          </div>

                          <p class="item-description text-sm text-gray-800">{{ showProductCartPopup.description }}</p>
                          
                          <div class="dates-section mt-4">

                              <div>

                                <div class="float-left mt-2 mb-2">
                                  <p class="text-sm">{{ $t('Quantity') }}</p>

                                  <vs-input-number class="inline-flex" 
                                      v-model="quantityRequested" 
                                      min="1" 
                                      :max="Number(showProductCartPopup.calculated)" 
                                      @input="changeProductQuantity()" 
                                  />
                                </div>

                                <div class="float-right mt-2 mb-2">
                                  <p class="text-sm">Modifier mes dates</p>
                                  
                                  <vs-switch class="mt-1 mr-2" v-model="changeDates"/>
                                </div>
                              </div>

                              <vs-row v-if="showProductCartPopup && !changeDates" class="mb-4 mt-4">
                                <vs-col vs-w="6">
                                    <h6>Date de départ</h6>
                                    <vs-row class="mt-2">
                                    <vs-col
                                        vs-lg="3"
                                        vs-xs="12"
                                        vs-sm="4"
                                        class="d-flex align-items-center justify-content-start justify-content-md-end"
                                    >
                                    </vs-col>
                                    </vs-row>
                                    <vs-row class="mt-2">
                                    <vs-col
                                        vs-lg="3"
                                        vs-xs="12"
                                        vs-sm="4"
                                    >
                                        <label class="vs-input--label mb-0">{{$t('Date')}} :</label>
                                    </vs-col>
                                    <vs-col vs-lg="9" vs-xs="12" vs-sm="8" v-if="selectedStartEndDateTime">
                                        {{ $moment(selectedStartEndDateTime.startDate, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY') }}
                                    </vs-col>
                                    </vs-row>
                                    <vs-row class="mt-2">
                                    <vs-col
                                        vs-lg="3"
                                        vs-xs="12"
                                        vs-sm="4"
                                    >
                                        <label class="vs-input--label mb-0">{{$t('Hour')}} :</label>
                                    </vs-col>
                                    <vs-col vs-lg="9" vs-xs="12" vs-sm="8" v-if="selectedStartEndDateTime">
                                        {{ $moment(selectedStartEndDateTime.startTime, 'DD-MM-YYYY HH:mm:ss').format('HH:mm') }}
                                    </vs-col>
                                    </vs-row>
                                </vs-col>

                                <vs-col vs-w="6">
                                    <h6>{{$t('returnDate')}} :</h6>
                                    <vs-row class="mt-2">
                                    <vs-col
                                        vs-lg="3"
                                        vs-xs="12"
                                        vs-sm="4"
                                        class="d-flex align-items-center justify-content-start justify-content-md-end"
                                    >
                                    </vs-col>
                                    </vs-row>

                                    <vs-row class="mt-2">
                                    <vs-col
                                        vs-lg="3"
                                        vs-xs="12"
                                        vs-sm="4"
                                    >
                                        <label class="vs-input--label mb-0">{{$t('Date')}} :</label>
                                    </vs-col>
                                    <vs-col vs-lg="9" vs-xs="12" vs-sm="8" v-if="selectedStartEndDateTime">
                                        {{ $moment(selectedStartEndDateTime.endDate, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY') }}
                                    </vs-col>
                                    </vs-row>
                                    <vs-row class="mt-2">
                                    <vs-col
                                        vs-lg="3"
                                        vs-xs="12"
                                        vs-sm="4"
                                    >
                                        <label class="vs-input--label mb-0">{{$t('Hour')}} :</label>
                                    </vs-col>
                                    <vs-col vs-lg="9" vs-xs="12" vs-sm="8" v-if="selectedStartEndDateTime">
                                        {{ $moment(selectedStartEndDateTime.endTime, 'DD-MM-YYYY HH:mm:ss').format('HH:mm') }}
                                        <br>
                                        <span class="text-danger text-sm" v-show="errors.has('step-1.end_time')">{{ errors.first('step-1.end_time') }}</span>

                                    </vs-col>
                                    </vs-row>
                                </vs-col>
                              </vs-row>

                              <vs-row v-if="showProductCartPopup && changeDates">
                                <vs-col vs-w="6">
                                    <h6>Date de départ</h6>
                                    <vs-row class="mt-2">
                                    <vs-col
                                        vs-lg="3"
                                        vs-xs="12"
                                        vs-sm="4"
                                        class="d-flex align-items-center justify-content-start justify-content-md-end"
                                    >
                                    </vs-col>
                                    </vs-row>
                                    <vs-row class="mt-2">
                                    <vs-col
                                        vs-lg="3"
                                        vs-xs="12"
                                        vs-sm="4"
                                    >
                                        <label class="vs-input--label mb-0">{{$t('Date')}} :</label>
                                    </vs-col>
                                    <vs-col vs-lg="9" vs-xs="12" vs-sm="8">
                                        <date-picker
                                        name="start_date" 
                                        ref="start_date" 
                                        v-model="selectedStartEndDateTime.startDate"
                                        :disabled-date="disabledStartDate"
                                        format="DD/MM/YYYY"
                                        placeholder="date de départ"
                                        v-validate="'required'"
                                        data-vv-as="date de départ"
                                        class="w-100"
                                        @change="selectedStartEndDateTimeChanged()"
                                        prefix-class="xmx"
                                        :editable="false"
                                        ></date-picker>

                                    </vs-col>
                                    </vs-row>
                                    <vs-row class="mt-2">
                                    <vs-col
                                        vs-lg="3"
                                        vs-xs="12"
                                        vs-sm="4"
                                    >
                                    <label class="vs-input--label mb-0">{{$t('Hour')}} :</label>
                                    </vs-col>
                                    <vs-col vs-lg="9" vs-xs="12" vs-sm="8">
                                        <date-picker
                                        class="mx-datepicker-time"
                                        v-model="selectedStartEndDateTime.startTime"
                                        name="start_time" 
                                        ref="start_time" 
                                        :time-picker-options="timePickerOptions"
                                        format="HH:mm"
                                        type="time"
                                        v-validate="'required'"
                                        data-vv-as="heure de retour"
                                        placeholder="--:--"
                                        @change="selectedStartEndDateTimeChanged()"
                                        prefix-class="xmx"
                                        :editable="false"
                                        ></date-picker>
                                    </vs-col>
                                    </vs-row>
                                </vs-col>

                                <vs-col vs-w="6">
                                    <h6>{{$t('returnDate')}} :</h6>
                                    <vs-row class="mt-2">
                                    <vs-col
                                        vs-lg="3"
                                        vs-xs="12"
                                        vs-sm="4"
                                        class="d-flex align-items-center justify-content-start justify-content-md-end"
                                    >
                                    </vs-col>
                                    </vs-row>

                                    <vs-row class="mt-2">
                                    <vs-col
                                        vs-lg="3"
                                        vs-xs="12"
                                        vs-sm="4"
                                    >
                                        <label class="vs-input--label mb-0">{{$t('Date')}} :</label>
                                    </vs-col>
                                    <vs-col vs-lg="9" vs-xs="12" vs-sm="8">
                                        <date-picker
                                        name="end_date" 
                                        ref="end_date" 
                                        v-model="selectedStartEndDateTime.endDate"
                                        :disabled-date="disabledEndDate"
                                        :disabled="selectedStartEndDateTime.startDate?false:true"
                                        format="DD/MM/YYYY"
                                        placeholder="date de retour"
                                        v-validate="'required'"
                                        data-vv-as="date de retour"
                                        class="w-100"
                                        @change="selectedStartEndDateTimeChanged()"
                                        prefix-class="xmx"
                                        :editable="false"
                                        ></date-picker>
                                    </vs-col>
                                    </vs-row>
                                    <vs-row class="mt-2">
                                    <vs-col
                                        vs-lg="3"
                                        vs-xs="12"
                                        vs-sm="4"
                                    >
                                        <label class="vs-input--label mb-0">{{$t('Hour')}} :</label>
                                    </vs-col>
                                    <vs-col vs-lg="9" vs-xs="12" vs-sm="8">
                                        <date-picker
                                        class="mx-datepicker-time"
                                        v-model="selectedStartEndDateTime.endTime"
                                        name="end_time" 
                                        ref="end_time" 
                                        :time-picker-options="timePickerOptions"
                                        format="HH:mm"
                                        type="time"
                                        v-validate="'required'"
                                        data-vv-as="heure de retour"
                                        placeholder="--:--"
                                        @change="selectedStartEndDateTimeChanged()"
                                        prefix-class="xmx"
                                        :editable="false"
                                        ></date-picker>

                                        <br>
                                        <span class="text-danger text-sm" v-show="errors.has('step-1.end_time')">{{ errors.first('step-1.end_time') }}</span>

                                    </vs-col>
                                    </vs-row>
                                </vs-col>
                              </vs-row>
                          </div>
                                              
                          <vs-row class="mt-3">
                              <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12"
                              v-for="(productOption, index) in productOptionsList"
                              :key="index"
                              >

                              <vs-row>
                                <vs-col type="flex" vs-w="5" vs-lg="4" vs-xs="6" vs-sm="6" class="mt-2">
                                <vs-checkbox class="justify-content-start" 
                                    :v-model="productOption.active" 
                                    :value="productOption.active" 
                                    v-on:change="onCheckProductOption(productOption, $event)" 
                                    > 
                                    
                                    {{ productOption.title }} : {{ productOption.price }} €
                                </vs-checkbox>
                                </vs-col>

                                <vs-col type="flex" vs-w="3" vs-lg="3" vs-xs="6" vs-sm="6">
                                <vs-input-number
                                    class="inline-flex"
                                    v-model="productOption.quantityRequested" 
                                    min="0"
                                    :max="productOption.qty" 
                                    @input="getNewProductOptionPrice(productOption)" 
                                /> 
                                </vs-col>
                                </vs-row>
                              </vs-col>
                          </vs-row>
                      </slot>

                  </div>
                </vs-col>
            </vs-row>
            
            <vs-row class="mt-5" vs-justify="flex-end">
                <button @click="cancelPopup()" class="bg-primary-border rounded-lg hover:opacity-75 sm:mb-6 xs:w-1/2 font-medium mr-1 py-2 px-3 rounded-md">
                  <svg xmlns="http://www.w3.org/2000/svg" class="inline h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  {{$t('Cancel')}} :
                </button>

                <button @click="confirmButtonPopupClicked()" class="bg-primary rounded-lg hover:opacity-75 sm:mb-6 xs:w-1/2 font-medium text-white py-2 px-3 rounded-md">
                  <svg xmlns="http://www.w3.org/2000/svg" class="inline h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                  {{$t('Book')}} :
                </button>
            </vs-row>
        </div>
    </vs-popup>
</div>
</template>

<script>

// DatePicker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import { mapState } from "vuex";
import moment from 'moment';

export default {
  props: {
    isPopupActive: {
      required: true
    },
    isFromAdminDash: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      popupBackgroundColor:"#f8f8f8",
      quantityRequested:1,
      quantityRequestedOption:0,
      productOptionsList:null,
      changeDates: false,
      selectedStartEndDateTime: null,
    }
  },
  watch: {
    isPopupActiveLocal (newValue, oldValue) {

        console.log('isPopupActiveLocal newValue', newValue)
        console.log('isPopupActiveLocal oldValue', oldValue)
      // console.log('newValue', newValue)
      this.productOptionsList = null

      this.quantityRequested = this.quantity?this.quantity:1
      this.selectedStartEndDateTime = this.showProductCartPopup.selectedStartEndDateTime

      console.log('isPopupActiveLocal showProductCartPopup', this.showProductCartPopup)
      console.log('isPopupActiveLocal selectedStartEndDateTime', this.selectedStartEndDateTime)

      if(this.showProductCartPopup) {
        console.log('start')
          const array = []
          this.showProductCartPopup.productOptionsList.forEach(productOption => {
            array.push({
              option_id: productOption.option_id,
              active: productOption.active,
              qty: productOption.qty,
              img: productOption.img,
              title: productOption.title,
              price: productOption.price,
              total: productOption.total,
              quantityRequested: productOption.quantityRequested,
            })
  
            this.productOptionsList = array
          });
      }

      console.log('productOptionsList', this.productOptionsList)

    }
  },
  components: {
    DatePicker,
  },
  computed: {
    isPopupActiveLocal: {
      get () {
        return this.isPopupActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }

    },
    ...mapState({
      showProductCartPopup: state => state.eCommerce.porductPopup,
    }),
    quantity: {
      get () {
        return this.showProductCartPopup.quantityRequested
      },
      set (value) {
        this.$store.commit('eCommerce/CHANGE_PRODUCT_QTY_POPUP', value)
      }
    },
		isCartUpdate: {
      get () {
        return this.$store.state.eCommerce.isCartUpdate
      }
    },
    timePickerOptions() {
      return {
        start: '08:30',
        step: '00:15',
        end: '18:00',
      }
    },
  },
  methods: {
    disabledStartDate(date) {

      if(this.isFromAdminDash) return false
      
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // const getStartDateFrom = typeof(this.reservationCustomerSettings.config.startDateFrom) !== 'undefined' ? this.reservationCustomerSettings.config.startDateFrom : 1
      // console.log('getStartDateFrom', getStartDateFrom)

      const getStartDateFrom = 2

      const startFrom = new Date(today);
      startFrom.setDate(startFrom.getDate() + getStartDateFrom);
      
      const numberOfDays = 270
      if(this.selectedStartEndDateTime.endDate != null && this.selectedStartEndDateTime.endDate != '') {
        return date < startFrom || date > this.selectedStartEndDateTime.endDate || date > new Date(startFrom.getTime() + numberOfDays * 24 * 3600 * 1000);
      }

      return date < startFrom || date > new Date(startFrom.getTime() + numberOfDays * 24 * 3600 * 1000);
    },
    disabledEndDate(date) {
      const startFrom = this.selectedStartEndDateTime ? this.selectedStartEndDateTime.startDate : null
      const numberOfDays = 270
      startFrom.setHours(0, 0, 0, 0);
      return date < startFrom || date > new Date(startFrom.getTime() + numberOfDays * 24 * 3600 * 1000);
    },
    changeProductQuantity() {
      if (this.quantityRequested > Number(this.showProductCartPopup.calculated) ) {
        this.quantityRequested = Number(this.showProductCartPopup.calculated)
      }
      this.$store.dispatch("eCommerce/popupChangeQuantity", this.quantityRequested);
	},
    onCheckProductOption: function(productOption, e) {
      // console.clear()
      // console.log('onCheckProductOption e', e.target.checked)
      // console.log('onCheckProductOption productOption', productOption)

      productOption.active = e.target.checked

      if (!e.target.checked) {
        productOption.quantityRequested = 0
        productOption.total = 0
      }
      else {
        if (productOption.quantityRequested == 0) {
          productOption.quantityRequested = 1
        }
        productOption.total = productOption.price * productOption.quantityRequested
      }

      const payload = {
        productOption: productOption,
        checked: e.target.checked,
      }
      
      this.$store.dispatch("eCommerce/popupChangeProductOptionQuantity", payload);
    },
    getNewProductOptionPrice(item) {
      // console.clear()
      // console.log('FROM getNewProductOptionPrice item', item)
      
      item.total = item.price * item.quantityRequested
      item.active = item.quantityRequested >= 1 ? true : false

      const payload = {
        productOption: item,
        checked: item.active,
      }

      this.$store.dispatch("eCommerce/popupChangeProductOptionQuantity", payload);
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async confirmButtonPopupClicked () { // BTN CONFIRMER
      
      if(this.selectedStartEndDateTime != null
      && this.selectedStartEndDateTime.startDate != null
      && this.selectedStartEndDateTime.startTime != null
      && this.selectedStartEndDateTime.endDate != null
      && this.selectedStartEndDateTime.endTime != null) {

        const productIsInStockRes = await this.productIsInStock()
        
        if (productIsInStockRes) {
          this.$store.dispatch('eCommerce/addProductInCart')
          this.isPopupActiveLocal = false
          await this.sleep(250);
          this.openCartNavbar()
        }

        else {
          this.$vs.notify({
            time: 7000,
            title:'Alert',
            text:'Quantité insuffisante avec les dates choisies.',
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle',
            position:'top-right'
          })
        }

      }

      else {
        this.$vs.notify({
          time: 4000,
          title:'Alert',
          text:'Vérifiez les données saisies.',
          color:'danger',
          iconPack: 'feather',
          icon:'icon-alert-circle',
          position:'top-right'
        })
      }

    },
    async productIsInStock() {
      var productIsInStockRes = false

      await this.$store.dispatch('eCommerce/checkIfProductIsInStock')
      .then(res => {
        productIsInStockRes = res
      })
      .catch(err => { 
        console.error(err)
      })

      return productIsInStockRes
    },
    updateButtonPopupClicked () { // BTN UPDATE
      console.log('updateButtonPopupClicked')
      this.$store.dispatch("eCommerce/updateItemInCart");
      this.isPopupActiveLocal = false
    },
    checkIfStartEndDateTimeIsValid() {
      if(this.selectedStartEndDateTime != null
      && this.selectedStartEndDateTime.startDate != null
      && this.selectedStartEndDateTime.startTime != null
      && this.selectedStartEndDateTime.endDate != null
      && this.selectedStartEndDateTime.endTime != null) {

        let startDate = moment(this.selectedStartEndDateTime.startDate).format('YYYY-MM-DD')
        let startTime = moment(this.selectedStartEndDateTime.startTime).format('HH:mm')
        
        let endDate = moment(this.selectedStartEndDateTime.endDate).format('YYYY-MM-DD')
        let endTime = moment(this.selectedStartEndDateTime.endTime).format('HH:mm')
  
        let startDateTime = moment(startDate + " " + startTime);
        let endDateTime = moment(endDate + " " + endTime);
        
        return endDateTime.diff(startDateTime, 'minutes') > 0 ? true : false
      }
      return true
    },
    selectedStartEndDateTimeChanged() {
      console.log('selectedStartEndDateTimeChangedd')
      if(this.selectedStartEndDateTime != null
      && this.selectedStartEndDateTime.startDate != null
      && this.selectedStartEndDateTime.startTime != null
      && this.selectedStartEndDateTime.endDate != null
      && this.selectedStartEndDateTime.endTime != null) {

        console.log('this.selectedStartEndDateTime', this.selectedStartEndDateTime)
        
        this.$store.dispatch("eCommerce/popupChangeSelectedStartEndDateTime", this.selectedStartEndDateTime);
        this.getAvProduct();
      }

    },
    async getAvProduct () {
      console.log('getAvProduct')
      this.$vs.loading()

      const payload = {
        startDate: moment(this.selectedStartEndDateTime.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.selectedStartEndDateTime.endDate).format('YYYY-MM-DD'),
        startTime: moment(this.selectedStartEndDateTime.startTime).format('HH:mm'),
        endTime: moment(this.selectedStartEndDateTime.endTime).format('HH:mm'),
        productId: this.showProductCartPopup.id,
        excludeReservationProductUuid: this.showProductCartPopup.reservationProductUuid,
        quantity: this.quantityRequested,

        notify: this.$vs.notify,
        loading: this.$vs.loading
      }
      await this.$store.dispatch('eCommerce/getAvProduct', payload)
      .then(res => {
        if (res.status == 200 || res.status == 201) {
          this.$vs.loading.close()
        }

        const product = res.data.data[0]

        product.total = product.price
        product.quantityRequested = this.quantityRequested
        product.selectedStartEndDateTime = this.selectedStartEndDateTime

        this.$store.dispatch("eCommerce/createEmptyPopup", product);
      })
      .catch(err => {
        console.error(err)
        this.selectedStartEndDateTime = this.showProductCartPopup.selectedStartEndDateTime
        this.$vs.loading.close() 
      })
      
    },
    cancelPopup() {
        console.log('cancelPopup')
        this.isPopupActiveLocal = false
    },
    openCartNavbar() {
      this.$store.commit("IS_CART_NAVBAR_ACTIVE", true);
    },

  },
	
}

</script>